import React, { useState, useEffect, useRef } from 'react';

import { Button, Checkbox, Form, Icon, Spin, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Select, Drawer, Divider } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database, functions } from '../firebase/firebase';
import Moment from 'moment';
import localization from 'moment/locale/es'
import AutoPlaySilentVideo from './AutoPlaySilentVideo';
import { userSignOut } from "appRedux/actions/Auth";

import {
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';


const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const backgroundImages = [
	require('../assets/images/slider-1-min.jpg'),
	require('../assets/images/slider-2-min.jpg'),
	require('../assets/images/slider-3-min.jpg')

  ];

class Programs extends React.Component {

	constructor() {
		super();
		this.state = {
			loading: false,
			subject: "Dudas sobre productos Chinoin®",
			message: '',
			showVisit: false,
			selectedVisit: '',
			visitStatus: "",
			userId: '',
			user: {},
			currentImageIndex: 0,

			email: '',
			visible: false,
			contactEmail: '',
			handleVisitLoader: false,

			contactSubject: 'Dudas sobre productos Chinoin®',
			contactMessage: '',
		};
		this.handleVisits = this.handleVisits.bind(this);

		this.handleSubmit = this.handleSubmit.bind(this);
		//this.setRedirect = this.setRedirect.bind(this);
		this.handleSubject = this.handleSubject.bind(this);
		this.handleEmail = this.handleEmail.bind(this);
		this.handleMessage = this.handleMessage.bind(this);
		

		// Bindings

	}
	
	componentDidMount() {
		var _this = this;
		this.interval = setInterval(() => {
			console.log("interval")
			this.setState(prevState => ({
			  currentImageIndex: (prevState.currentImageIndex + 1) % backgroundImages.length
			}));
		  }, 5000);
		//this.adjustDivHeights(); // Adjust heights on initial load
		auth.onAuthStateChanged((user) => {
			if (user) {
				_this.getUserInformation(user);
			} else {
				_this.setState({
					user: {}
				});
			}
		});
	}

	componentWillUnmount() {
		clearInterval(this.interval);

	}
	handleSubmit = (e) => {
		e.preventDefault();

		if (this.state.contactEmail != "" && this.state.contactSubject != "" && this.state.contactMessage != "") {
			if (/\S+@\S+\.\S+/.test(this.state.contactEmail)) {
				//	if(this.state.contactEmail.includes("@") &&this.state.contactEmail.includes(".")){

				var addMessage = functions.httpsCallable('contactform');

				var dateMessage = Moment(new Date())
					.locale('es')
					.format('D [de] MMMM [del]  YYYY');
				var _this = this;
				addMessage({
					email: this.state.contactEmail,
					subject: this.state.contactSubject,
					date: dateMessage,
					message: this.state.contactMessage
				})
					.then(function (result) {
						console.log("sucess")
						alert("Enviado!")
						_this.setState({ contactEmail: '', contactSubject: '', contactMessage: '' })
						// Read result of the Cloud Function.
						_this.setState({ visitStatus: "success" })
						//var sanitizedMessage = result.data.text;
					})
					.catch(function (error) {

						_this.setState({ visitStatus: "error" })

						// Getting the Error details.
						var code = error.code;
						var message = error.message;
						var details = error.details;

						console.log("Function error")
						console.log(code)
						console.log(message)
						//console.log(details)
						// ...
					});
			} else {
				alert("Correo electrónico inválido")
			}
		} else {
			alert("Por favor llene todos los campos")
		}

	};
	handleVisits() {
		console.log("handleVisits")
		this.setState({ handleVisitLoader: true })

		var addMessage = functions.httpsCallable('medicalvisit');
		Moment().locale('es', localization)

		var dateMessage = Moment(new Date())
			.locale('es')
			.format('D [de] MMMM [del]  YYYY');
		var _this = this;
		addMessage({
			userId: this.state.userId,

			date: dateMessage,
			method: this.state.selectedVisit
		})
			.then(function (result) {
				console.log("sucess")
				_this.setState({ contactEmail: '', contactSubject: '', contactMessage: '' })
				_this.setState({ handleVisitLoader: false })

				// Read result of the Cloud Function.
				_this.setState({ visitStatus: "success" })
				//var sanitizedMessage = result.data.text;
			})
			.catch(function (error) {

				_this.setState({ visitStatus: "error" })

				// Getting the Error details.
				var code = error.code;
				var message = error.message;
				var details = error.details;
				_this.setState({ handleVisitLoader: false })

				console.log("Function error")
				console.log(code)
				console.log(message)
				//console.log(details)
				// ...
			});
	}
	getUserInformation(user) {
		var _this = this;
		database
			.collection('users')
			.where('email', '==', user.email)
			.get()
			.then(function (querySnapshot) {
				querySnapshot
					.forEach(function (doc) {
						_this.setState({
							user: doc.data(),
							userId: doc.id
						});
						_this.getUserAccessSeries();
					});
			})
			.catch(function (error) { });
	}




	handleSubject(e) {
		console.log(e);
		this.setState({ contactSubject: e });
	}
	handleMessage(e) {
		console.log(e.target.value);
		this.setState({ contactMessage: e.target.value });
	}
	handleEmail(e) {
		console.log(e.target.value);
		this.setState({ contactEmail: e.target.value });
	}
	componentDidUpdate() { }

	render() {
		const backgroundImageStyle = {
			backgroundImage: 'url("slider-3-min.jpg")',
		  };
		const ColoredLine = ({ color }) => (
			<hr
				style={{
					color: color,
					backgroundColor: color,
					height: 1
				}}
			/>
		);

		const ProductRow = ({ name, image, link, loyalty }) => (
			<di>
				<br></br>	<br></br>
				<Row class="product">

					<Col lg={6} md={4} sm={2} xs={2}></Col>

					<Col lg={12} md={16} sm={20} xs={20}>
						<div className='comparative-box'>
							<Row>
								<Col lg={4} md={4} sm={4} xs={4}></Col>
								<Col lg={16} md={16} sm={16} xs={16}>

									<div className="top-white">	<span class="farma-name">{name}</span></div>
								</Col>
								<Col lg={4} md={4} sm={4} xs={4}></Col>
							</Row>
							<br></br>	<br></br>
							<Row>
								<Col lg={3} md={3} sm={2} xs={2}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>
									<img class="comparative-item" src={require('../assets/images/norutec_10_14.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
								<Col lg={2} md={2} sm={1} xs={1}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>
									<img class="comparative-item" src={require('../assets/images/norutec_20_14.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
								<Col lg={2} md={2} sm={1} xs={1}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>
									<img class="comparative-item" src={require('../assets/images/norutec_20_28.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></Col>
								<Col lg={3} md={3} sm={1} xs={1}></Col>
							</Row>
							<Row>
								<Col lg={3} md={3} sm={2} xs={2}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>

									<span class="product-dec">Norutec 10 mg<br></br>14 Tabletas</span>
								</Col>
								<Col lg={2} md={2} sm={1} xs={1}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>

									<span class="product-dec">Norutec 20 mg<br></br>14 Tabletas</span>
								</Col>
								<Col lg={2} md={2} sm={1} xs={1}></Col>
								<Col lg={5} md={5} sm={6} xs={6}>

									<span class="product-dec">Norutec 20 mg<br></br>28 Tabletas</span>
								</Col>
								<Col lg={3} md={3} sm={2} xs={2}></Col>
							</Row>
							<Row>
								<Col span={2}></Col>
								<Col span={20}><ColoredLine color="white" /></Col>
								<Col span={2}></Col>
							</Row>
							<Row>
								<Col span={2}></Col>
								<Col lg={16} md={16} sm={20} xs={20}>
									{loyalty ?
										<span class="loyalty-span"><span className='spaced-text-blue'>PLAN DE LEALTAD: </span>{loyalty}<br></br><br></br></span> : null}
								</Col>
								<Col lg={0} md={0} sm={8} xs={8}></Col>
								<Col lg={4} md={4} sm={8} xs={8}>

									<div class="buy-button" ><a target="_blank" href={link}><span>Comprar</span></a></div></Col>
							</Row>
							<br></br>

						</div>
					</Col>
					<Col lg={6} md={4} sm={2} xs={2}></Col>
				</Row>
				<br></br>
				<br></br>

			</di>
		);

		return (

			<div className="scrollable">
				<img className="background-video" src={require('../assets/images/video_back_altern.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />
				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back_altern.mp4")} videowebm={require("../assets/images/video_back_altern.webm")} />
				<HorizontalDefault />

				<div class="menu-mobile">

					<div>
						<Row span={24}>

							<Col span={1}></Col>
							<Col span={3}>
								<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
							</Col>
							<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
							<Col span={7}>
								<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
							</Col>
							<Col span={2}></Col>
							<Col span={6}>
								<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
							</Col>
							<Col span={1}></Col>

						</Row>

						<Drawer


							placement="right"
							closable={false}
							visible={this.state.visible}
						>
							<br></br>
							<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
							<a href="/"><p class="drawer-item">ACERCA DE</p></a>
							<Divider />
							<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
							<ul>
								<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

								<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

								<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
							</ul>
							<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
							<Divider />
							<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
							<Divider />
							{(this.state.user != {} && this.state.user != null) ? <a onClick={() => this.props.userSignOut()}><p class="drawer-item">CERRAR SESIÓN</p></a> : null}
						</Drawer>
					</div>


				</div>

				<div className="">
					<div class="mobile-spacer">
					</div>
					<Row>

						<Row className="rows-slider-back" >
						<img className="rows-slider-back-img" src={backgroundImages[this.state.currentImageIndex]} alt="innovacion_chinoin" title="innovacion_chinoin" />

							<div className='logo-balace-div'>
								<div className='hl-blue'></div>
								<img class='gastrobalance-base' src={require('../assets/images/base_azul.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
								<img class='gastrobalance-logo' src={require('../assets/images/logo_gastrob.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
							</div>
						
						</Row>
						<Col span={24}>
							<div className='white-video-back'>
								<AutoPlaySilentVideo idName="white-logo-video-effect-info" videowebm={require("../assets/images/white-video.webm")} video={require("../assets/images/white-video.mp4")} />

								<br></br>	<br></br>	<br></br>
								{/* Inicio de la sección de comparativa*/}

   
								<Row >


									<Col lg={4} md={4} sm={2} xs={2}></Col>
									<Col lg={8} md={8} sm={10} xs={10} className='left-box'>
										<Row>
											<Col span={24} className='end-text shift'><span className='gastro-section-title-end shift-more'>PROGRAMAS ALIMENTICIOS </span></Col>
										</Row>
										<Row className='margin-top-extra margin-bottom-on-small'>

											<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-1.jpg')} title="innovacion_chinoin" /></div></Col>
											<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Dispepsia Hombre 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente masculino con dispepsia, el cual, además de ayudarlo o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/1%20Dispepsia%20Hombre%201%2C900%20kcal.pdf?alt=media&token=e5fef433-7741-4159-b62a-17363c099228'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>

										<Row >
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-3.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Helicobacter pylori Hombre 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente masculino con Helicobacter pylori, el cual, además de ayudarlo o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/3%20Helicobacter%20pylori%20Hombre%201%2C900%20kcal.pdf?alt=media&token=5c587fba-46f6-4a94-a0eb-90c02f4e97c1'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>
										<Row >
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pros-5.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Reflujo nocturno Hombre 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente masculino con reflujo nocturno, el cual, además de ayudarlo o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/5%20Reflujo%20nocturno%20Hombre%201%2C900%20kcal.pdf?alt=media&token=5ea18db9-fd97-4812-9d6a-5171fec3ab58'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>
										<Row className='margin-bottom-on-small'>
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-7.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Úlcera péptica Hombre 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente masculino con úlcera péptica, el cual, además de ayudarlo o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/7%20U%CC%81lcera%20pe%CC%81ptica%20Hombre%201%2C900%20kcal.pdf?alt=media&token=e8d1e57d-a12e-46c0-8f7d-1670e7cc8563'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>
									</Col>
									<Col lg={8} md={8} sm={10} xs={10} className='right-box'>
										<Row>
											<Col span={20}></Col>
											<Col span={4}>‎</Col>
										</Row>

										<Row className='margin-top-extra margin-bottom-on-small'>
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-2.jpg')} title="innovacion_chinoin" /></div></Col>
											<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Dispepsia Mujer 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente femenino con dispepsia, el cual, además de ayudarla o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/2%20Dispepsia%20Mujer%201%2C900%20kcal.pdf?alt=media&token=b31705ba-f1e2-4453-a360-4b4faae06b84'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>

										<Row >
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-4.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Helicobacter pylori Mujer 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente femenino con Helicobacter pylori, el cual, además de ayudarla o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/4%20Helicobacter%20pylori%20Mujer%201%2C900%20kcal.pdf?alt=media&token=84e2c1ea-77f0-4410-bc58-c5bcf57d6d3d'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>
										
										<Row >
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-6.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Reflujo nocturno Mujer 1,900 kcal	</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente femenino con reflujo nocturno, el cual, además de ayudarla o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/6%20Reflujo%20nocturno%20Mujer%201%2C900%20kcal.pdf?alt=media&token=7170d54f-d6b6-40cc-a1b3-c1a99e0131a7'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>

										<Row className='margin-bottom-on-small'>
										<Col lg={5} md={5} sm={24} xs={24}><div className='padding-left-extra'><img class="image-gastro padding-both" src={require('../assets/images/pro-8.jpg')} title="innovacion_chinoin" /></div></Col>
										<Col lg={14} md={14} sm={19} xs={19}><span class="gastro-title">Úlcera péptica Mujer 1,900 kcal</span><p className='gastro-subtext'>Menú para los siete días de la semana diseñado especialmente para el paciente femenino con úlcera péptica, el cual, además de ayudarla o controlar su peso, contribuirá a mejorar su enfermedad.</p></Col>
											<Col lg={0} md={0} sm={3} xs={3}></Col>
											<Col lg={4} md={4} sm={19} xs={19}><a target="_blank" href='https://firebasestorage.googleapis.com/v0/b/innovacionchinoin.appspot.com/o/8%20U%CC%81lcera%20pe%CC%81ptica%20Mujer%201%2C900%20kcal.pdf?alt=media&token=13397247-f574-42f7-ae28-829fa16f50d7'><div class="gastro-button"><span>Ver</span></div></a></Col>
										</Row>
									
									</Col>
									<Col lg={4} md={4} sm={2} xs={2}></Col>
								</Row>
							

								<br></br><br></br><br></br><br></br>
							</div>
							{/* Inicio de la sección de Paralaje*/}

							<Row>
								<Col span={24}>
									<div class="parallax5">
										<img class="home-icon-down" src={require('../assets/images/down_arrow.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
									</div>

								</Col>
							</Row>
							<br></br><br></br><br></br><br></br>
							{/* Inicio de la sección de texto*/}

							<section id="contacto">
								<Row>
									<Col lg={3} md={3} sm={3} xs={3}></Col>


									<Col lg={8} md={8} sm={19} xs={19}>
										<br></br>	<br></br>
										<div class="desktop-spacer">
											<br></br>	<br></br>	<br></br>	<br></br>	<br></br>


										</div>
										<h3 class="h3-title">GASTRO-IN</h3>
										<h2 className='h2-title'>Contacto</h2>
										<p class="regular-text">Para cualquier aclaración relacionada con esta plataforma, utilice nuestro <span class="highligh-text">Formulario de Contacto</span></p>

										<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">


											<span class="regular-text">Email*</span>

											<Input rows={3} placeholder="e.g email@ejemplo.com" onChange={this.handleEmail}
												value={this.state.contactEmail} />

											<br></br>	<br></br>
											<span class="regular-text">Asunto*</span>

											<Select
												name="subject"
												onChange={this.handleSubject}
												value={this.state.contactSubject}
												placeholder="Asunto"
											>
												<Option value="Dudas sobre productos Chinoin®">Dudas sobre productos Chinoin®</Option>
												<Option value="Contacto Chinoin®">Contacto Chinoin®</Option>
												<Option value="Soporte Académico">Soporte Académico</Option>
												<Option value="Soporte Técnico">Soporte Técnico</Option>

											</Select>

											<br></br>	<br></br>
											<span class="regular-text">Mensaje*</span>
											<TextArea rows={4} placeholder="Mensaje" onChange={this.handleMessage}
												value={this.state.contactMessage} />
											<br></br>	<br></br>
											<FormItem>
												<p
													id='contact-button'
													type="dashed"
													className="home-button-gray centered-text margin-left"
													htmlType="submit"
													onClick={this.handleSubmit}
												>
													<span class="regular-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
												</p>


											</FormItem>

										</Form>

									</Col>
									<Col lg={4} md={4} sm={0} xs={0}></Col>
									<Col lg={7} md={7} sm={0} xs={0}>
										<br></br>	<br></br>	<br></br>	<br></br>	<br></br>
										<div className='base-cont'>
											<AutoPlaySilentVideo idName="logo-video-effect" videowebm={require("../assets/images/sparkle.webm")} video={require("../assets/images/sparkle.mov")} />


											<img class='walking' src={require('../assets/images/men_walking.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />


										</div>

									</Col>
									<Col lg={2} md={2} sm={2} xs={2}></Col>
								</Row>
							</section>
						</Col>
					</Row>

					<br></br><br></br>
					<div class="desktop-spacer">
						<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
					</div>


					<Row id="footer-gradient">
						<Col span={2}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<ul className="list-no-bullet">

								<li>
									<br></br>
									<Row>
										<Col lg={0} md={0} sm={2} xs={2}></Col>


										<Col lg={18} md={18} sm={18} xs={18}>
											<span class="footer-small-text">Plataforma académica patrocinada por Productos Farmacéuticos Chinoin®<br></br><br></br><br></br></span>

											<span class="footer-small-text">Productos Farmacéuticos Chinoin®<br></br>

												Lago Tangañica #18<br></br>
												Colonia Granada, 11520<br></br>
												México, D.F.<br></br>
												(55) 5262 31 00<br></br>
												Fax: (55) 5531 43 49<br></br>
												ventas@chinoin.com</span>
										</Col>
									</Row>
								</li>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}></Col>

						<Col lg={4} md={4} sm={19} xs={19}>
							<ul id="social-ul" className='margin-left' >
								<li><img class="footer-logo-norutec" src={require('../assets/images/norutec_alone.png')} /></li>
								<br></br>
								<li><img class="footer-logo-norutec" src={require('../assets/images/benequer.png')} /></li>

								<br></br>
								<li><img class="footer-logo-brand" src={require('../assets/images/talnesis.png')} /></li>
								<br></br>
								<li><img class="footer-logo-brand-gelan" src={require('../assets/images/gelan-small.webp')} /></li>
								<br></br>
								<li><img class="footer-logo-brand-gelan" src={require('../assets/images/anara.png')} /></li>
								<br></br>	<br></br>
								<li><img class="footer-logo-brand-inter" src={require('../assets/images/logo-intersistemas.png')} />
									<br></br>
									<span id="dev-text"> Desarrollado por Intersistemas S.A de C.V</span>
								</li>
								<br></br>		<br></br>			<br></br>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<span class="regular-text">Accesos rápidos</span>
							<ul className="list-no-bullet">
								<li><a href="/#acerca"> <span className='footer-links'>Acerca de</span></a></li>
								<li><a href="profesional-salud"> <span className='footer-links'>Acceso Profesional de la Salud</span></a></li>
								<li><a href="gastrobalance"> <span className='footer-links'>Gastrobalance</span></a></li>
								<li><a href="puntosdeventa"> <span className='footer-links'>Puntos de venta</span></a></li>
								<li><a href="https://www.chinoin.com/"> <span className='footer-links'>Sobre Chinoin</span></a></li>
								<li><a href="https://www.chinoingastroinnovacion.com/aviso" target="_blank"> <span className='footer-links'>Aviso de privacidad</span></a></li>
								<div className='mobile-spacer'>
									<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
								</div>


							</ul>
						</Col>

					</Row>
					<a href="https://www.chinoin.com/" target="_blank"><img id="corner-logo" src={require('../assets/images/esquina.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

					<div id="social">
						<ul id="social-ul">

							<li className='social-list'><a href="https://www.facebook.com/ChinoinProductosFarmaceuticos" target="_blank"><img class='social-icon' src={require('../assets/images/face.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.instagram.com/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/insta.webp')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.linkedin.com/company/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/in.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.tiktok.com/@chinoinpf?is_from_webapp=1&sender_device=pc" target="_blank"><img class='social-icon' src={require('../assets/images/tiktok.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://twitter.com/ChinoinPF" target="_blank"><img class='social-icon' src={require('../assets/images/twitter.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.youtube.com/channel/UC3SguU-Dc1L61rFbBm8Lbww" target="_blank"><img class='social-icon' src={require('../assets/images/youtube.png')} alt="" title="" /></a></li>


						</ul>

					</div>


				</div>

				{this.state.showVisit ? <div class="videoView">
					<div className="visitDiv">
						<img class="close" onClick={() => this.setState({ showVisit: false })} src={require('../assets/images/close.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

						<div id="centered">
							<div className='rcorners2' >
								<br></br>
								<Row>
									<Col span={10}></Col>
									<Col span={12}>
										<span class="home-text-small">Gastro-In</span><br></br>
									</Col>
								</Row>
								<Row>
									<Col span={2}></Col>
									<Col span={20}>
										<span class="font-huge">Solicitud de Visita Médica</span><br></br><br></br>
										<span class="visit-text-blue">Nos podremos en contacto con usted en breve</span><br></br>
										<span class="visit-text">¿Cuál de los siguientes medios es el de su preferencia?</span><br></br>
										<Dropdown id="visitselect" overlayStyle={{ backgroundColor: "#000" }} overlay={<Menu>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "Correo electrónico" })}>
												<p className="dark-span" >Correo Electrónico</p>
											</Menu.Item>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "WhatsApp" })}>
												<p className="dark-span" >WhatsApp</p>

											</Menu.Item>
											<Menu.Item onClick={() => this.setState({ selectedVisit: "Correo Electrónico y WhatsApp" })}>
												<p className="dark-span">Correo electrónico y WhatsApp</p>

											</Menu.Item>
										</Menu>} placement="bottomCenter">
											<div class="home-button-gray"><p className='clear-span'>{this.state.selectedVisit == '' ? "Seleccione su opción  " : this.state.selectedVisit}&nbsp;&nbsp;<Icon type="caret-down" style={{ fontSize: '16px', color: '#FFF' }} theme="outlined" /> </p></div>
										</Dropdown>
										<span class="visit-text">De click en ACEPTAR para confirmar su Solicitud de Visita Médica</span><br></br>

										{this.state.handleVisitLoader ? <Spin tip="Enviando..." /> :
											<div class="home-button" onClick={this.handleVisits}><span>Aceptar</span></div>}									</Col>
								</Row>
								<Row>
									<Col span={6}></Col>

								</Row>
								<br></br>	<br></br>
								{this.state.visitStatus == "success" ? <span class="visit-text-blue">¡Su solicitud ha sido enviada!</span> : <div></div>}
								{this.state.visitStatus == "error" ? <span class="visit-text-blue">Se produjo un error</span> : <div></div>}

								<br></br>

							</div>
						</div>
					</div>


				</div> : <div></div>}

			</div>
		);
	}
}
const WrappedNormalForgetForm = Form.create()(Programs);

export default connect(null, { userSignOut })(Programs);
