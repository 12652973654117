import React from 'react';
import { Button, Checkbox, Form, Icon, Input, message, Dropdown, Menu, Card, Row, Col, Layout, Select, Drawer, Divider } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { auth, database, functions } from '../firebase/firebase';
import Moment from 'moment';
import AutoPlaySilentVideo from './AutoPlaySilentVideo';
import {
	userSignOut,
	hideMessage,
	showAuthLoader,
	userFacebookSignIn,
	userGithubSignIn,
	userGoogleSignIn,
	userSignIn,
	userTwitterSignIn
} from 'appRedux/actions/Auth';

import IntlMessages from 'util/IntlMessages';
import CircularProgress from 'components/CircularProgress/index';
import Topbar from './Topbar/index';
import HorizontalDefault from './Topbar/HorizontalDefault/index';



const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

class Info extends React.Component {

	constructor() {
		super();
		this.state = {
			loading: false,
			subject: "Dudas sobre productos Chinoin®",
			message: '',
			showVideo: false,
			videoId: '',
			videoTitle: '',
			videoCategory: '',
			email: '',
			isSafari:false,
			visible: false,
			contactEmail: '',
			contactSubject: 'Dudas sobre productos Chinoin®',
			contactMessage: '',
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		//this.setRedirect = this.setRedirect.bind(this);
		//this.handleSignIn = this.handleSignIn.bind(this);
		this.handleSubject = this.handleSubject.bind(this);
		this.handleEmail = this.handleEmail.bind(this);
		this.handleMessage = this.handleMessage.bind(this);


	}
	handleSubmit = (e) => {
		e.preventDefault();

		if (this.state.contactEmail != "" && this.state.contactSubject != "" && this.state.contactMessage != "") {
			//if (/\S+@\S+\.\S+/.test(this.state.contactEmail)) {
			//if (this.state.contactSubject.includes("@") && this.state.contactSubject.includes(".")) {
			if (/\S+@\S+\.\S+/.test(this.state.contactEmail)) {
				var addMessage = functions.httpsCallable('contactform');

				var dateMessage = Moment(new Date())
					.locale('es')
					.format('D [de] MMMM [del]  YYYY');
				var _this = this;
				addMessage({
					email: this.state.contactEmail,
					subject: this.state.contactSubject,
					date: dateMessage,
					message: this.state.contactMessage
				})
					.then(function (result) {
						console.log("sucess")
						alert("Enviado!")
						_this.setState({ contactEmail: '', contactSubject: '', contactMessage: '' })
						// Read result of the Cloud Function.
						_this.setState({ visitStatus: "success" })
						//var sanitizedMessage = result.data.text;
					})
					.catch(function (error) {

						_this.setState({ visitStatus: "error" })

						// Getting the Error details.
						var code = error.code;
						var message = error.message;
						var details = error.details;

						console.log("Function error")
						console.log(code)
						console.log(message)
						//console.log(details)
						// ...
					});
			} else {
				alert("Correo electrónico inválido")
			}
		} else {
			alert("Por favor llene todos los campos")
		}

	};

	handleSubject(e) {
		console.log(e);
		this.setState({ contactSubject: e });
	}
	handleMessage(e) {
		console.log(e.target.value);
		this.setState({ contactMessage: e.target.value });
	}
	handleEmail(e) {
		console.log(e.target.value);
		this.setState({ contactEmail: e.target.value });
	}

	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};
	state = {
		searchText: '',
		user: {},
		visible: false,
	};


	getUserInformation(user) {
		var _this = this;
		database
			.collection('users')
			.where('email', '==', user.email)
			.get()
			.then(function (querySnapshot) {
				querySnapshot
					.forEach(function (doc) {
						_this.setState({
							user: doc.data(),
							userId: doc.id
						});
						_this.getUserAccessSeries();
					});
			})
			.catch(function (error) { });
	}

	componentDidMount() {
		var _this = this;
		var safari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
		this.setState({isSafari:safari})
		auth.onAuthStateChanged((user) => {
			if (user) {
				_this.getUserInformation(user);
			} else {
				_this.setState({
					user: {}
				});
			}
		});

		var lemail = localStorage.getItem("user_email")
		var pass = localStorage.getItem("user_password")
		var id = localStorage.getItem('user_id')

		if (lemail != null && pass != null) {
			if (lemail != undefined && pass != undefined) {
				if (lemail != "" && pass != "") {
					//if (/\S+@\S+\.\S+/.test(lemail)) {
					//if(this.state.includes("@") &&this.state.includes(".")){
					if (lemail.includes("@") && lemail.includes(".")) {
	
						if (id == null || id == undefined) {
	
							database.collection('users').where('email', '==', lemail).get().then(function (snapshot) {
								if (snapshot.docs.length != 0) {
	
	
									auth.signInWithEmailAndPassword(lemail, pass)
										.then((authUser) => {
											localStorage.setItem('user_id', authUser.user.uid);
										})
										.catch(error => {
											console.log("");
										})
								} else {
									console.log("");
	
								}
							});
						} else {
							this.getUserInformation({ email: lemail })
						}
					}
				}
	
			}
		}
	}

	componentDidUpdate() {
		console.log("update");
	}

	render() {
		const { SubMenu } = Menu;

		const menu = (
			<Menu>
				<Menu.Item><span className='menu-title'>ACERCA DE</span></Menu.Item>
				<Menu.Item>2nd menu item</Menu.Item>
				<SubMenu title="sub menu">
					<Menu.Item>3rd menu item</Menu.Item>
					<Menu.Item>4th menu item</Menu.Item>
				</SubMenu>
				<SubMenu title="disabled sub menu" disabled>
					<Menu.Item>5d menu item</Menu.Item>
					<Menu.Item>6th menu item</Menu.Item>
				</SubMenu>
			</Menu>
		);
		const handleVideoClick = (videoid, videoTitle, videoCategory) => {

			console.log('The link was clicked.');
			this.setState({ showVideo: true, videoId: videoid, videoTitle: videoTitle, videoCategory: videoCategory });
		}
		return (
			<div className="scrollable">

				{/*<video id="background-video" ref={this} autoplay="true" loop="true" muted="true" width={"100%"} height={"100%"} playsInline controls={false} preload='auto' autobuffer>
					Your browser does not support the video tag.
					<source src={require("../assets/images/video_back.mp4")} type='video/mp4' />
					<source src={require("../assets/images/video_back.webm")} type='video/webm' />
		</video>*/}
				<img className="background-video" src={require('../assets/images/thumbnail.jpg')} alt="innovacion_chinoin" title="innovacion_chinoin" />
				<AutoPlaySilentVideo className="background-video" video={require("../assets/images/video_back.mp4")} videowebm={require("../assets/images/video_back.webm")} videoogg={require("../assets/images/video_back.ogg")} />

				<HorizontalDefault />

				<div class="menu-mobile">

<div>
	<Row span={24}>

		<Col span={1}></Col>
		<Col span={3}>
			<div onClick={() => this.setState({ visible: true })}><Icon type="menu" style={{ fontSize: '36px', color: '#fff' }} /> </div>
		</Col>
		<Col span={4}><a href="/"><img id="home-icon" src={require('../assets/images/home_icon.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a></Col>
		<Col span={7}>
			<img src={require('../assets/images/logo-main-small.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={2}></Col>
		<Col span={6}>
			<img id="slight-top" src={require('../assets/images/norutec.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
		</Col>
		<Col span={1}></Col>

	</Row>

	<Drawer


		placement="right"
		closable={false}
		visible={this.state.visible}
	>
		<br></br>
		<Icon type="close" style={{ fontSize: '30px', color: '#fff', float: 'right' }} onClick={() => this.setState({ visible: false })} />
		<a href="/"><p class="drawer-item">ACERCA DE</p></a>
		<Divider />
		<a href="/profesional-salud"><p class="drawer-item">PROFESIONAL DE LA SALUD</p></a>
		<ul>
			<li><a href="/educacion-medica"><p class="drawer-subitem">EDUCACIÓN MÉDICA CONTINUA</p></a><Divider></Divider></li>

			<li><a href="/informacion-producto"><p class="drawer-subitem">INFORMACIÓN DE PRODUCTO</p></a><Divider></Divider></li>

			<li><a href="/experiencia-clinica"><p class="drawer-subitem">EXPERIENCIA CLÍNICA</p></a><Divider></Divider></li>
		</ul>
		<a href="/gastrobalance"><p class="drawer-item">GASTROBALANCE</p></a>
		<Divider />
		<a href="/#contacto"><p class="drawer-item">CONTACTO</p></a>
		<Divider />
		{(this.state.user != {} && this.state.user != null) ? <a onClick={() => userSignOut() }><p class="drawer-item">CERRAR SESIÓN</p></a> : null}
	</Drawer>
</div>


</div>


				<div className="">
					<div class="mobile-spacer">
						<br></br>	<br></br>	<br></br>	<br></br>	<br></br>
					</div>
					<Row>
						<Col span={24}>

							<Row span={24}>
								<Col lg={6} md={4} sm={2} xs={2}></Col>
								<Col lg={12} md={16} sm={20} xs={20}>
									<div className='base-cont'>
										{/*<video id="logo-video-effect" autoPlay loop muted >
										<source src={require("../assets/images/sparkle.mp4")} type='video/mp4;codecs=hvc1' />

											<source src={require("../assets/images/sparkle.webm")} type='video/webm' />
										</video>
	*/}
										{this.state.isSafari?
										<AutoPlaySilentVideo idName="logo-video-effect" video={require("../assets/images/sparkle.mov")} />:<AutoPlaySilentVideo idName="logo-video-effect"  videowebm={require("../assets/images/sparkle.webm")} video={require("../assets/images/sparkle.mov")} />}
										<img class="base-logo" src={require('../assets/images/logo-main-fused.png')} />

									</div>
								</Col>
								<Col lg={6} md={4} sm={2} xs={2}></Col>

							</Row>
							<br></br><br></br>
							{/* Inicio de la sección de botones*/}
							<Row>
								<Col lg={5} md={5} sm={2} xs={2}></Col>
								<Col lg={7} md={7} sm={20} xs={20} className="margin-sides">

									<div className='rcorners2'>
										<Row>
											<Col span={17}>	<img class="home-icon" src={require('../assets/images/icono_home_scope.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
												<span class="home-text-small long-text">Acceso al Profesional <br></br>de la Salud</span></Col>
											<Col span={6}><div class="home-button-gray-trans"><a href="/profesional-salud"><span>Ingresar</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col lg={1} md={1} sm={2} xs={2}></Col>
								<Col lg={7} md={7} sm={20} xs={20} className="margin-left">
									<div className='rcorners2'>
										<Row>
											<Col span={17}><img class="home-icon" src={require('../assets/images/home_icon_store.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /><span class="home-text-small">Puntos de venta</span></Col>
											<Col span={6}><div class="home-button-gray-trans"><a href="/puntosdeventa"><span>Ver</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col span={6}></Col>
							</Row>
							<br></br>	<br></br>

							<Row>
								<Col lg={5} md={5} sm={2} xs={2}></Col>
								<Col lg={7} md={7} sm={20} xs={20} >
									<div className='rcorners2'>
										<Row>
											<Col span={17}><img class="home-icon" src={require('../assets/images/gastro-icon-section.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /><span class="home-text-small">Gastrobalance</span></Col>
											<Col span={6}><div class="home-button-gray-trans"><a href='/gastrobalance'><span>Entrar</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col lg={1} md={1} sm={2} xs={2}></Col>
								<Col lg={7} md={7} sm={20} xs={20} className="margin-left margin-top">
									<div className='rcorners2'>
										<Row>
											<Col lg={14} md={14} sm={16} xs={16}><img class="home-icon" src={require('../assets/images/home_icon_shield.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /><span class="home-text-small">Acerca de<br></br>Chinoin®</span></Col>
											<Col lg={9} md={9} sm={7} xs={7}><div class="home-button-gray-trans"><a href="https://www.chinoin.com/" target="_blank"><span>Conocer más</span></a></div></Col>
										</Row>
									</div>
								</Col>
								<Col span={6}></Col>
							</Row>
							<br></br>	<br></br><br></br><br></br><br></br>	<br></br><br></br><br></br>
							{/* Inicio de la sección de Paralaje*/}

							<Row>
								<Col span={24}>
									<div class="parallax">

										<img class="home-icon-down" src={require('../assets/images/down_arrow.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

									</div>

								</Col>
							</Row>
							<br></br>	<br></br><br></br>
							<section id="acerca">
								<div class="desktop-spacer">
									<br></br><br></br>	<br></br><br></br>


								</div>



								<Row>
									<Col lg={2} md={2} sm={3} xs={3}></Col>
									<Col lg={9} md={9} sm={0} xs={0}>
										<div className='base-cont'>
											<AutoPlaySilentVideo idName="logo-video-effect" videowebm={require("../assets/images/sparkle.webm")} video={require("../assets/images/sparkle.mov")} />
											<img class='walking' src={require('../assets/images/woman_walking.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
										</div>

									</Col>
									<Col lg={2} md={2} sm={0} xs={0}></Col>
									<Col lg={9} md={9} sm={19} xs={19}>
										<h3 class="h3-title">GASTRO-IN</h3>
										<h2 className='h2-title'>Acerca de</h2>
										<p class="regular-text-info"><span class="highligh-text">Gastro-In</span> un espacio desarrollado para la comunidad de profesionales de la salud interesados en padecimientos Gastrointestinales, dentro de esta plataforma académica usted encontrará contenido actualizado y de calidad, con la finalidad de mantenerlo al día y seguir aportando a su Educación Médica Continua con materiales avalados por las principales Sociedades Médicas y con puntaje de recertificación
											<br></br><br></br>
											<span class="bigger-welcome-text">¡Bienvenidos!</span><br></br><br></br><br></br><div class="desktop-spacer"><br></br><br></br><br></br><br></br></div></p>
									</Col>
									<Col span={2}></Col>
								</Row>
								<div class="desktop-spacer">
									<br></br><br></br>	<br></br><br></br>
								</div>

							</section>

							<Row>
								<Col span={24}>
									<div class="parallax2">
										<img class="home-icon-down" src={require('../assets/images/down_arrow.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

									</div>
								</Col>
							</Row>
							<section id="contacto">
								<Row>
									<Col lg={3} md={3} sm={3} xs={3}></Col>


									<Col lg={8} md={8} sm={19} xs={19}>
										<br></br>	<br></br>
										<div class="desktop-spacer">
											<br></br>	<br></br>	<br></br>	<br></br>	<br></br>


										</div>
										<h3 class="h3-title">GASTRO-IN</h3>
										<h2 className='h2-title'>Contacto</h2>
										<p class="regular-text">Para cualquier aclaración relacionada con esta plataforma, utilice nuestro <span class="highligh-text">Formulario de Contacto</span></p>

										<Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">


											<span class="regular-text">Email*</span>

											<Input rows={3} placeholder="e.g email@ejemplo.com" onChange={this.handleEmail}
												value={this.state.contactEmail} />

											<br></br>	<br></br>
											<span class="regular-text">Asunto*</span>

											<Select
												name="subject"
												onChange={this.handleSubject}
												value={this.state.contactSubject}
												placeholder="Asunto"
											>
												<Option value="Dudas sobre productos Chinoin®">Dudas sobre productos Chinoin®</Option>
												<Option value="Contacto Chinoin®">Contacto Chinoin®</Option>
												<Option value="Soporte Académico">Soporte Académico</Option>
												<Option value="Soporte Técnico">Soporte Técnico</Option>

											</Select>

											<br></br>	<br></br>
											<span class="regular-text">Mensaje*</span>
											<TextArea rows={4} placeholder="Mensaje" onChange={this.handleMessage}
												value={this.state.contactMessage} />
											<br></br>	<br></br>
											<FormItem>
												<p
													id='contact-button'
													type="dashed"
													className="home-button-gray centered-text margin-left"
													htmlType="submit"
													onClick={this.handleSubmit}
												>
													<span class="regular-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enviar&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
												</p>


											</FormItem>

										</Form>

									</Col>
									<Col lg={4} md={4} sm={0} xs={0}></Col>
									<Col lg={7} md={7} sm={0} xs={0}>
										<br></br>	<br></br>
										<div class="desktop-spacer">
											<br></br>	<br></br>	<br></br>
										</div>
										<div className='base-cont'>
											<AutoPlaySilentVideo idName="logo-video-effect" videowebm={require("../assets/images/sparkle.webm")} video={require("../assets/images/sparkle.mov")} />


											<img class='walking' src={require('../assets/images/men_walking.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />


										</div>

									</Col>
									<Col lg={2} md={2} sm={2} xs={2}></Col>
								</Row>
							</section>
						</Col>
					</Row>
					<br></br><br></br>
					<div class="desktop-spacer">
					<br></br><br></br><br></br><br></br><br></br><br></br><br></br>
										</div>
				

					<Row id="footer-gradient">
						<Col span={2}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<ul className="list-no-bullet">

								<li>
									<br></br>
									<Row>
										<Col lg={0} md={0} sm={2} xs={2}></Col>


										<Col lg={18} md={18} sm={18} xs={18}>
											<span class="footer-small-text">Plataforma académica patrocinada por Productos Farmacéuticos Chinoin®<br></br><br></br><br></br></span>

											<span class="footer-small-text">Productos Farmacéuticos Chinoin®<br></br>

												Lago Tangañica #18<br></br>
												Colonia Granada, 11520<br></br>
												México, D.F.<br></br>
												(55) 5262 31 00<br></br>
												Fax: (55) 5531 43 49<br></br>
												ventas@chinoin.com</span>
										</Col>
									</Row>
								</li>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}></Col>

						<Col lg={4} md={4} sm={19} xs={19}>
							<ul id="social-ul" className='margin-left' >
								<li><img class="footer-logo-norutec" src={require('../assets/images/norutec_alone.png')} /></li>
								<br></br>	
								<li><img class="footer-logo-norutec" src={require('../assets/images/benequer.png')} /></li>

								<br></br>	
								<li><img class="footer-logo-brand" src={require('../assets/images/talnesis.png')} /></li>
								<br></br>	
								<li><img class="footer-logo-brand-gelan" src={require('../assets/images/gelan-small.webp')} /></li>
								<br></br>
								<li><img class="footer-logo-brand-gelan" src={require('../assets/images/anara.png')} /></li>
								<br></br>	<br></br>	
								<li><img class="footer-logo-brand-inter" src={require('../assets/images/logo-intersistemas.png')} />
									<br></br>
									<span id="dev-text"> Desarrollado por Intersistemas S.A de C.V</span>
								</li>
								<br></br>		<br></br>			<br></br>
							</ul>
						</Col>
						<Col lg={4} md={4} sm={4} xs={4}>

						</Col>
						<Col lg={4} md={4} sm={20} xs={20}>
							<span class="regular-text">Accesos rápidos</span>
							<ul className="list-no-bullet">
								<li><a href="/#acerca"> <span className='footer-links'>Acerca de</span></a></li>
								<li><a href="profesional-salud"> <span className='footer-links'>Acceso Profesional de la Salud</span></a></li>
								<li><a href="gastrobalance"> <span className='footer-links'>Gastrobalance</span></a></li>
								<li><a href="puntosdeventa"> <span className='footer-links'>Puntos de venta</span></a></li>
								<li><a href="https://www.chinoin.com/"> <span className='footer-links'>Sobre Chinoin</span></a></li>
								<li><a href="https://www.chinoingastroinnovacion.com/aviso" target="_blank"> <span className='footer-links'>Aviso de privacidad</span></a></li>
								<div className='mobile-spacer'>
								<br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
							</div>
							

							</ul>
						</Col>

					</Row>
					<a href="https://www.chinoin.com/" target="_blank"><img id="corner-logo" src={require('../assets/images/esquina.png')} alt="innovacion_chinoin" title="innovacion_chinoin" /></a>

					<div id="social">
						<ul id="social-ul">

							<li className='social-list'><a href="https://www.facebook.com/ChinoinProductosFarmaceuticos" target="_blank"><img class='social-icon' src={require('../assets/images/face.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.instagram.com/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/insta.webp')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.linkedin.com/company/chinoinpf/" target="_blank"><img class='social-icon' src={require('../assets/images/in.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.tiktok.com/@chinoinpf?is_from_webapp=1&sender_device=pc" target="_blank"><img class='social-icon' src={require('../assets/images/tiktok.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://twitter.com/ChinoinPF" target="_blank"><img class='social-icon' src={require('../assets/images/twitter.png')} alt="" title="" /></a></li>
							<li className='social-list'><a href="https://www.youtube.com/channel/UC3SguU-Dc1L61rFbBm8Lbww" target="_blank"><img class='social-icon' src={require('../assets/images/youtube.png')} alt="" title="" /></a></li>

						</ul>

					</div>



				</div>
				{this.state.showVideo ? <div class="videoView">
					<div className="conChild">
						<img class="close" onClick={() => this.setState({ showVideo: false })} src={require('../assets/images/close.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />

						<div id="centered">
							<div className='rcorners2' >
								<br></br>
								<Row>
									<Col span={10}></Col>
									<Col span={6}>
										<span class="home-text-small">Gastro-In</span><br></br>
									</Col>
								</Row>
								<Row>
									<Col span={2}></Col>
									<Col span={20}>
										<span class="text-h3-cent">Estamos construyendo algo nuevo para ti</span><br></br><br></br>
									</Col>
								</Row>
								<Row>
									<Col span={10}></Col>
									<Col span={6}>
										<img class="event-icon-small-fixed" src={require('../assets/images/construyendo.png')} alt="innovacion_chinoin" title="innovacion_chinoin" />
									</Col>
								</Row>

								<br></br>

							</div>
						</div>
					</div>

				</div> : <div></div>}


			</div>
		);
	}
}
const WrappedNormalForgetForm = Form.create()(Info);

export default connect(null, { userSignOut })(Info);
